var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-new-sidebar","visible":_vm.isAddNewSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"hidden":_vm.resetForm,"change":function (val) { return _vm.$emit('update:is-add-new-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Adicionar ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"refFormObserver"},[_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.submit()},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"image","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Capa do congresso  ","label-for":"image"}},[_c('b-form-file',{attrs:{"placeholder":"Selecione a foto de perfil...","drop-placeholder":"Arraste seu arquivo para aqui...","multiple":""},model:{value:(_vm.form.image),callback:function ($$v) {_vm.$set(_vm.form, "image", $$v)},expression:"form.image"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"user_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nome completo","label-for":"user_name"}},[_c('b-form-input',{attrs:{"id":"user_name","state":errors.length > 0 ? false : null},model:{value:(_vm.form.user_name),callback:function ($$v) {_vm.$set(_vm.form, "user_name", $$v)},expression:"form.user_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"user_cpf","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"CPF","label-for":"user_cpf"}},[_c('b-form-input',{attrs:{"id":"user_cpf","state":errors.length > 0 ? false : null,"type":"number"},model:{value:(_vm.form.user_cpf),callback:function ($$v) {_vm.$set(_vm.form, "user_cpf", $$v)},expression:"form.user_cpf"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"user_email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Email","label-for":"user_email"}},[_c('b-form-input',{attrs:{"id":"user_email","state":errors.length > 0 ? false : null},model:{value:(_vm.form.user_email),callback:function ($$v) {_vm.$set(_vm.form, "user_email", $$v)},expression:"form.user_email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"name_badge","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nome cracha","label-for":"name_badge"}},[_c('b-form-input',{attrs:{"id":"name_badge","state":errors.length > 0 ? false : null},model:{value:(_vm.form.name_badge),callback:function ($$v) {_vm.$set(_vm.form, "name_badge", $$v)},expression:"form.name_badge"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"pronome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Pronome","label-for":"pronome"}},[_c('v-select',{attrs:{"id":"pronome","options":_vm.pronome,"label":"Pronome","placeholder":"Selecione a opção","state":errors.length > 0 ? false : null},model:{value:(_vm.form.pronome),callback:function ($$v) {_vm.$set(_vm.form, "pronome", $$v)},expression:"form.pronome"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"mini_curriculo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Curriculo","label-for":"mini_curriculo"}},[_c('b-form-textarea',{attrs:{"id":"mini_curriculo","state":errors.length > 0 ? false : null,"rows":"3","max-rows":"6"},model:{value:(_vm.form.mini_curriculo),callback:function ($$v) {_vm.$set(_vm.form, "mini_curriculo", $$v)},expression:"form.mini_curriculo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"lattes_link","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Lattes","label-for":"lattes_link"}},[_c('b-form-input',{attrs:{"id":"lattes_link","state":errors.length > 0 ? false : null},model:{value:(_vm.form.lattes_link),callback:function ($$v) {_vm.$set(_vm.form, "lattes_link", $$v)},expression:"form.lattes_link"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"instagram","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Instagram","label-for":"instagram"}},[_c('b-form-input',{attrs:{"id":"instagram","state":errors.length > 0 ? false : null},model:{value:(_vm.form.instagram),callback:function ($$v) {_vm.$set(_vm.form, "instagram", $$v)},expression:"form.instagram"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Senha","label-for":"password"}},[_c('b-form-input',{attrs:{"id":"password","state":errors.length > 0 ? false : null,"type":"password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" Adicionar ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" Cancelar ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }